import cx from 'classnames';
import type { ComponentPropsWithoutRef } from 'react';

import { Icon } from '../Icon';

import { useControl } from './ControlProvider';

interface FeedbackMessageProps extends ComponentPropsWithoutRef<'p'> {
  variant?: 'error' | 'info' | 'info-dark';
  withIcon?: boolean;
}

export const FeedbackMessage = ({ className, variant, children, withIcon, ...rest }: FeedbackMessageProps) => {
  const control = useControl();
  return (
    <p className={cx('flex', className)} {...rest} {...control.feedbackMessageProps}>
      <span className={cx('flex flex-row justify-center', variant ? variants[variant] : null)}>
        {variant === 'info' && withIcon && <Icon name="Info" className="text-coldgray-600" />}
        <span
          className={cx('text-xs-medium', {
            'text-coldgray-600': variant === 'info',
            'text-secondary': variant === 'info-dark',
            'text-xs-regular': variant === 'error',
            'pl-1': withIcon,
          })}
        >
          {children}
        </span>
      </span>
    </p>
  );
};

const variants = {
  error: 'text-error',
  info: 'text',
  'info-dark': '',
};
